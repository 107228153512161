<template>
  <div>
    <div class="alert alert-warning">
      <strong>Please note!</strong> This area is being phased out. Some reports may redirect you to the new reports area. This can also be accessed by
      clicking <strong class="mx-1"><i class="fa fa-file-chart-pie mr-1"></i> Reports</strong> in the side menu bar.
    </div>
    <div v-for="category in categories" :key="category.value">
      <SectionTitle class="mb-4" :title="category.title" />
      <div class="row">
        <div v-for="report in filteredReports(category)" :key="report.type" class="col-md-6 col-xl-3">
          <ReportCard :report="report" @download="onClickDownload" @view="onClickView" />
        </div>
      </div>
    </div>
    <ConfirmModal :open="!!modals.download" :title="downloadModalTitle" @close="modals.download = false" @submit="onDownload(modals.download)">
      <div v-if="modals.download">
        <FormGroup
          v-if="modals.download.confirmDates"
          id="confirm-dates"
          v-model="options.dateRange"
          label="Report Date Range"
          name="confirm-dates"
          type="dateRangePicker"
        />
      </div>
      <div v-if="modals.download.params">
        <FormGroup
          v-for="(param, key) in modals.download.params"
          :id="key"
          :key="key"
          v-model="options[key]"
          :label="param.label"
          :name="key"
          :type="param.type"
          :options="param.options"
          :description="param.description"
        />
      </div>
    </ConfirmModal>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';
import ReportCard from '@/components/reports/ReportCard';
import SectionTitle from '@/components/base/SectionTitle';

const apiFormat = 'DD/MM/YYYY';
const todayDate = moment();
const todayLastYear = moment().subtract(1, 'years');

export default {
  name: 'PortfolioReportList',
  components: {
    ConfirmModal,
    FormGroup,
    SectionTitle,
    ReportCard
  },
  data() {
    return {
      modals: {
        remove: false,
        download: false
      },
      options: {
        dateRange: [todayLastYear, todayDate]
      },
      filter: '',
      search: '',
      categories: [
        { title: 'PDF Reports', value: 'pdf' },
        { title: 'Data Exports', value: 'data' },
        { title: 'Consumption Reports', value: 'consumption' },
        { title: 'Miscellaneous Reports', value: 'misc' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      assetGroup: 'assetGroup/assetGroup'
    }),
    downloadModalTitle() {
      if (!this.modals.download) return 'N/A';
      return `Download ${this.modals.download.name} Report`;
    },
    reports() {
      return [
        // {
        //   name: 'Portfolio Report',
        //   type: 'report',
        //   fileType: 'PDF',
        //   description: 'Portfolio level report',
        //   canDownload: false, // TODO
        //   canView: true
        // },
        {
          name: 'Accounts',
          type: 'asset-accounts-table',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'An export of all the accounts associated with this portfolio',
          canDownload: true,
          canView: false,
          adminOnly: false,
          category: 'data'
        },
        {
          name: 'Accounts Status',
          type: 'accounts-status',
          description: 'An export of all accounts displaying status data',
          canDownload: true,
          canView: false,
          format: 'xlsx',
          category: 'data'
        },
        {
          name: 'Invoices',
          type: 'asset-invoices',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'An export of all the invoices associated with this portfolio',
          canDownload: true,
          canView: false,
          confirmDates: true,
          category: 'data'
        },
        {
          name: 'Consolidated Consumption',
          type: 'consolidated-consumption',
          description: 'Invoice and smart data consumption combined into one report',
          canDownload: true,
          canView: false,
          confirmDates: true,
          format: 'xlsx',
          params: {},
          category: 'consumption'
        },
        {
          name: 'Dwellant Report',
          type: 'dwellant',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'Dwellant report generated from all invoices in this portfolio',
          canDownload: true,
          canView: false,
          confirmDates: true,
          category: 'misc',
          companyId: '5ff855f8d19326001546e9e4'
        },
        {
          name: 'Consumption Summary',
          type: 'asset-consumption-table',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'A summary of consumption and cost data for each account',
          canDownload: true,
          canView: false,
          adminOnly: false,
          confirmDates: true,
          category: 'consumption',
          params: {
            sourceData: {
              label: 'Data Source',
              default: 'invoice',
              options: [
                { label: 'Invoices', value: 'invoice' },
                { label: 'Readings', value: 'reading' },
                { label: 'Smart Data', value: 'consumption' }
              ],
              type: 'radio',
              description: 'Choose which data set to use for this report'
            },
            emissions: {
              label: 'Show Emission Data',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Display emission data in the report'
            },
            simulated: {
              label: 'Display Simulated Consumption',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Choose which data set to use for this report'
            },
            dayNightSplit: {
              label: 'Day/Night Split',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Choose whether to split out day and night consumption'
            }
          }
        },
        {
          name: 'Consumption (Monthly)',
          type: 'asset-consumption-detailed-table',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'Consumption and cost data for each account with monthly breakdown',
          canDownload: true,
          canView: false,
          confirmDates: true,
          category: 'consumption',
          params: {
            sourceData: {
              label: 'Data Source',
              default: 'invoice',
              options: [
                { label: 'Invoices', value: 'invoice' },
                { label: 'Readings', value: 'reading' },
                { label: 'Smart Data', value: 'consumption' }
              ],
              type: 'radio',
              description: 'Choose which data set to use for this report'
            },
            emissions: {
              label: 'Show Emission Data',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Display emission data in the report'
            },
            simulated: {
              label: 'Display Simulated Consumption',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Choose which data set to use for this report'
            },
            dayNightSplit: {
              label: 'Day/Night Split',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Choose whether to split out day and night consumption'
            }
          }
        },
        {
          name: 'Consumption (Like-for-like)',
          type: 'asset-consumption-lfl',
          format: 'xlsx',
          description: 'Consumption and data using a baseline and showing like-for-like data',
          canDownload: true,
          canView: false,
          confirmDates: true,
          params: {
            baseline: {
              label: 'Baseline Year',
              default: moment().subtract(1, 'year').year(),
              options: [
                moment().subtract(1, 'year').year(),
                moment().subtract(2, 'year').year(),
                moment().subtract(3, 'year').year(),
                moment().subtract(4, 'year').year(),
                moment().subtract(5, 'year').year()
              ].map(year => ({ label: year, value: year })),
              type: 'select',
              description: 'Show which data is like for like based on the below baseline year'
            },
            emissions: {
              label: 'Show Emission Data',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Display emission data in the report'
            },
            simulated: {
              label: 'Display Simulated Consumption',
              default: false,
              options: [
                { label: 'No', value: false },
                { label: 'Yes', value: true }
              ],
              type: 'radio',
              description: 'Choose which data set to use for this report'
            }
          },
          category: 'consumption'
        },
        {
          name: 'Data Quality',
          type: 'asset-data-quality-table',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'Missing invoices, readings and other data for this asset',
          canDownload: true,
          canView: false,
          confirmDates: true,
          adminOnly: false,
          category: 'misc'
        },
        {
          name: 'Projects Summary',
          type: 'asset-projects',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'Status of projects',
          canDownload: true,
          canView: false,
          category: 'data'
        },
        {
          name: 'Verco Report',
          type: 'verco',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'Verco report generated from all assets in this portfolio',
          canDownload: true,
          canView: false,
          adminOnly: false,
          companyId: '5ff855f8d19326001546e9e4',
          category: 'misc',
          confirmDates: true
        },
        {
          name: 'Cushman & Wakefield Data',
          type: 'cushman',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'Report that provides data required for Cushman & Wakefield',
          canDownload: true,
          canView: false,
          adminOnly: false,
          companyId: '655b6d833f36810014b817a1',
          category: 'misc',
          confirmDates: true,
          permissions: ['company_user', 'company_admin']
        },
        {
          name: 'EPAM Data',
          type: 'epam',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'Report that provides data required for EPAM',
          canDownload: true,
          canView: false,
          adminOnly: false,
          companyId: '655b6d833f36810014b817a1',
          category: 'misc',
          confirmDates: true,
          permissions: ['company_user', 'company_admin']
        },
        {
          name: 'Scrapers',
          type: 'accounts-scrapers',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'Accounts with scrapers enabled',
          canDownload: true,
          canView: false,
          category: 'misc'
        },
        {
          name: 'Missing Asset Data',
          type: 'asset-missing-data',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'Missing data on all assets in this portfolio',
          canDownload: true,
          canView: false,
          category: 'data'
        },
        {
          name: 'Missing Account Data',
          type: 'accounts-missing-data',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'Missing data on accounts',
          canDownload: true,
          canView: false,
          category: 'data'
        },
        {
          name: 'Discussion Board Comments',
          type: 'asset-discussion-comments',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'View all discussion board comments generated from all assets in this portfolio',
          canDownload: true,
          canView: false,
          category: 'data'
        },
        {
          name: 'Contracts',
          type: 'accounts-contracts',
          fileType: 'Excel',
          format: 'xlsx',
          description: 'An export of all the contracts associated with this entity',
          canDownload: true,
          canView: false,
          category: 'data'
        },
        {
          name: 'EPC',
          type: 'asset-epc',
          description: 'Summary of all EPC certificates',
          canDownload: true,
          canView: false,
          confirmDates: false,
          format: 'xlsx',
          category: 'misc'
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      downloadReport: 'report/download'
    }),
    isAuthorized(report) {
      if (this.$auth.isAdmin || (!report.companyId && !report.permissions)) return true;

      if (!report.permissions) return report.companyId === this.$auth.companyId;

      if (!report.companyId) return report.permissions.some(perm => this.$permissions.has(perm));

      return report.companyId === this.$auth.companyId && report.permissions.some(perm => this.$permissions.has(perm));
    },
    filteredReports(category) {
      const reports = this.reports.filter(report => report.category === category.value && this.isAuthorized(report));
      reports.sort((a, b) => a.name.localeCompare(b.name));
      return reports;
    },
    onClickView(report) {
      this.$router.push({ name: 'portfolio-reports-view', params: { reportType: report.type } });
    },
    onClickDownload(report) {
      if (report.template) {
        return this.$router.push({
          name: 'reports-generate',
          query: { template: report.template }
        });
      }

      if (report.params) {
        Object.entries(report.params || {}).forEach(([key, value]) => {
          this.$set(this.options, key, typeof value.default === 'undefined' ? '' : value.default);
        });
      }

      this.modals.download = report;
    },
    onDownload(report) {
      let query = null;

      query = {
        startDate: moment(this.options.dateRange[0]).format(apiFormat),
        endDate: moment(this.options.dateRange[1]).format(apiFormat),
        format: report.format
      };

      if (report.params) {
        Object.keys(report.params)
          .filter(key => key !== 'notes')
          .forEach(key => {
            if (this.options[key]) query[key] = this.options[key];
          });
      }

      this.modals.download = false;

      this.$router.push({
        name: 'portfolio-reports-download',
        params: { reportType: report.type, format: report.format },
        query
      });
    }
  }
};
</script>
